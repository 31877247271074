<template>
	<div class="grid">
		<div class="col-12">
			<Card>
                <template #title>
                    {{title}}
                </template>
                <template #content>
					<!-- Toolbar -->
					<Toolbar class="mb-4">
						<template #start>
							<Button v-if="$can('kegiatan-add')" label="Tambah" icon="pi pi-plus" class="p-button-primary mr-2" @click="openNew" />
							<Button v-if="$can('kegiatan-delete')" label="Hapus" icon="pi pi-trash" class="p-button-danger" 
								@click="openDelete('multi')" :disabled="!dataTable.selectedData || !dataTable.selectedData.length" />
						</template>
					</Toolbar>

					<!-- Data Table -->
					<DataTable class="p-datatable-gridlines p-datatable-sm" filterDisplay="menu" :rowHover="true" :lazy="true" :paginator="true" 
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport" 
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
						v-model:filters="dataTable.params.filters"
						v-model:selection="dataTable.selectedData" dataKey="id"
						:rowsPerPageOptions="dataTable.rows_option"
						:value="dataTable.data"
						:rows="dataTable.params.rows" 
						:totalRecords="dataTable.totalRecords" 
						:loading="dataTable.loading" 
						@page="loadData($event)" @sort="loadData($event)">
						<template #header>
							<div class="flex justify-content-between flex-column sm:flex-row">
								<div class="p-fluid">
									<MultiSelect v-model="dataTable.selectedColumns" :options="dataTable.columns"  optionValue="field" optionLabel="header" placeholder="Select Columns" display="chip" style="width: 20em"/>
								</div>
								<span class="p-input-icon-left mb-2">
									<i class="pi pi-search" />
									<InputText v-model="dataTable.params.filters['global'].value" @keydown.enter="loadData()" :placeholder="dataTable.global_placeholder" v-tooltip.top.focus="'Press Enter'" style="width: 100%"/>
								</span>
							</div>
						</template>
						<template #empty>
							<div class="p-text-center">No data found.</div>
						</template>
						<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
						<!-- Columns -->
						<Column v-for="(col, i) of dataTable.columns.filter(col => dataTable.selectedColumns.includes(col.field))" :field="col.field" :header="col.header" :sortable="col.sort" :showFilterMatchModes="false" :key="i">
							<template #body="{data}">
								<div v-if="col.default">{{ data[col.field] }}</div>
								<div v-if="col.field == 'kegiatan'">{{ data.kegiatan?.nama }}</div>
								<div v-if="col.field == 'action'">
									<Button v-if="$can('kegiatan-edit')" @click="openEdit(data)" v-tooltip.top="'Edit'" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-primary mr-2" />
									<Button v-if="$can('kegiatan-delete')" @click="openDelete('single', data)" v-tooltip.top="'Hapus'" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" />
								</div>
							</template>

							<template v-if="col.filter" #filter="{filterModel,filterCallback}">
								<InputText v-if="col.default" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="column-filter" placeholder="Search" />
							</template>
						</Column>
						<!-- End Columns -->
					</DataTable>

					<small>Note : Tambahkan data terlebih dahulu, kemudian edit untuk membuat template</small>

					<!-- Form -->
					<Dialog v-model:visible="dialog.formData" :header="formDataTitle" :style="{width: '800px'}" :modal="true" :maximizable="true" :dismissableMask="true" class="p-fluid">
						<div class="field">
							<label>Kegiatan</label>
							<Dropdown v-model="dataForm.kegiatan_id" :options="relations.kegiatan" display="chip"
								optionValue="id"
								optionLabel="name"
								:class="{ 'p-invalid': dataForm.errors.has('kegiatan_id') }" />
							<small class="p-error" v-show="dataForm.errors.has('kegiatan_id')">
								{{ dataForm.errors.get('kegiatan_id') }}
							</small>
						</div>
						<div class="field">
							<label>Nomor</label>
							<InputText v-model="dataForm.nomor"
								:class="{ 'p-invalid': dataForm.errors.has('nomor') }" />
							<small class="p-error" v-show="dataForm.errors.has('nomor')">
								{{ dataForm.errors.get('nomor') }}
							</small>
						</div>
						<div class="field">
							<label>Background</label>
							<InputText @change="selectFile" type="file" accept="image/*"
								:class="{ 'p-invalid': dataForm.errors.has('background') }" />
							<small v-show="dataForm.mode == 'edit' && dataForm.background" class="text-primary">
								<b>URL</b> : {{ backgroundPathURL+'/'+dataForm.background }}
							</small>
							<small class="p-error" v-show="dataForm.errors.has('background')">
								{{ dataForm.errors.get('background') }}
							</small>
						</div>
						<div v-if="dataForm.mode == 'edit'" class="field">
							<label>Template</label>
							<Editor
								v-model="dataForm.template"
								:class="{ 'p-invalid': dataForm.errors.has('template') }"
								:api-key="$tinymce_api_key"
								:init="{
									height: 400,
									plugins: 'print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media codesample table charmap hr nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons',
									menubar: 'file edit view insert format tools table help',
									toolbar: 'undo redo | bold italic underline strikethrough | charmap emoticons | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview print | image media link anchor codesample | ltr rtl',
									valid_children: '+body[style]'
								}"
							/>
							<small class="p-error" v-show="dataForm.errors.has('template')">
								{{ dataForm.errors.get('template') }}
							</small>
						</div>
						<div v-if="dataForm.mode == 'edit'" class="field">
							<Accordion>
								<AccordionTab header="Merge Field">
									<DataTable :value="merge_field" responsiveLayout="scroll" :scrollable="true">
										<Column field="field"></Column>
										<Column field="merge">
											<template #body="{data}">
												<div>: {{ data.merge }}</div>
											</template>
										</Column>
									</DataTable>
								</AccordionTab>
							</Accordion>
						</div>

						<template #footer>
							<Button label="Save" @click="submitData" :disabled="dataForm.busy" :icon="(dataForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-text" style="float:right" />
							<Button label="Cancel" @click="dialog.formData = false" icon="pi pi-times" class="p-button-text" />
						</template>
					</Dialog>

					<!-- Delete Confirmation -->
					<Dialog v-model:visible="dialog.deleteData" header="Confirmation" :style="{width: '350px'}" :modal="true" :dismissableMask="true" class="p-fluid">
						<div class="confirmation-content">
							<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
							<span>Do you want to delete this record?</span>
						</div>
						<template #footer>
							<Button label="No" icon="pi pi-times" @click="dialog.deleteData = false" class="p-button-text"/>
							<Button label="Yes" icon="pi pi-check" @click="deleteData" class="p-button-text" autofocus />
						</template>
					</Dialog>
				</template>
			</Card>

		</div>
	</div>

</template>

<script>
export default {
	data() {
		return {
			title: 'Sertifikat',
			api: '/api/sertifikat',
			backgroundPathURL: `${process.env.VUE_APP_ASSETS_FOLDER}/sertifikat_background`,
			dataForm: new this.$FormAuth({
				mode: null,
				id: null,
				kegiatan_id: null,
				nomor: null,
				background: null,
				template: null,
			}),
			merge_field: [
				{ field: "Nomor", merge: "{{nomor}}" },
				{ field: "Nama Kegiatan", merge: "{{nama_kegiatan}}" },
				{ field: "Tanggal Kegiatan", merge: "{{tanggal_kegiatan}}" },
				{ field: "Waktu Mulai Kegiatan", merge: "{{waktu_mulai_kegiatan}}" },
				{ field: "Waktu Selesai Kegiatan", merge: "{{waktu_selesai_kegiatan}}" },
				{ field: "Nama Peserta", merge: "{{nama_peserta}}" },
			],
			relations: {
				kegiatan: []
			},
			dataTable: {
				data: [],
				loading: false,
				global_placeholder: 'nomor',
				totalRecords: 0,
				rows_option: [10, 25, 50, 100],
				selectedData: [],
				columns: [
					{field: 'kegiatan', header: 'Kegiatan', sort: true, filter: false},
					{field: 'nomor', header: 'Nomor', sort: true, filter: true, default: true},
					{field: 'action', header: 'Action', sort: false, filter: false},
				],
				selectedColumns: ['kegiatan', 'nomor', 'action'],
				params: {
					filters: {
						global: {value: null},
						nomor: {value: null},
					},
					rows: 10, //per_page
					page: 0,
					sortField: null,
					sortOrder: null,
				},
			},
			dialog: {
				formData: false,
				deleteData: false,
			},
			formDataTitle: null,
		}
	},
	methods: {
		loadData(event = null){
			this.$Progress.start();
			this.dataTable.loading = true;
			(event) ? this.dataTable.params = event : null;
			this.$httpAuth.get(this.api, {
				params: {
					rows: this.dataTable.params.rows,
					sortField: this.dataTable.params.sortField,
					sortOrder: this.dataTable.params.sortOrder,
					filters: this.dataTable.params.filters,
					page: this.dataTable.params.page + 1,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.dataTable.loading = false;
				this.dataTable.data = response.data.data;
				this.dataTable.totalRecords = response.data.total;
			})
			.catch((error) => {
				this.$Progress.fail();
				this.dataTable.loading = false;
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},

		getKegiatan(){
            this.$httpAuth.get(this.api + '/get/kegiatan')
            .then((response) => {
                this.relations.kegiatan = response.data;
            })
            .catch((error) => {
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },

		selectFile(e){
			const file = e.target.files[0];
			this.dataForm.background = file;
		},

		openNew() {
			this.resetForm();
			this.dataForm.mode = 'add';
			this.formDataTitle = 'Tambah '+this.title;
			this.dialog.formData = true;
			this.getKegiatan();
		},
		openEdit(data){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/' + data.id + '/edit')
			.then((response) => {
				this.$Progress.finish();
				this.resetForm();
				this.dataForm.fill(response.data);
				this.dataForm.mode = 'edit';
				this.formDataTitle = 'Edit '+this.title;
				this.dialog.formData = true;
				this.getKegiatan();
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		openDelete(mode = null, data = null){
			if(mode == 'single'){
				this.dataTable.selectedData = [];
				this.dataTable.selectedData.push(data);
			}
			this.dialog.deleteData = true;
		},
		resetForm(){
			this.dataForm.clear();
			this.dataForm.reset();
		},
		submitData(){
			this.$Progress.start();
			if(this.dataForm.mode == 'add'){
				this.dataForm.post(this.api)
				.then((response) => {
					this.$Progress.finish();
					this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
					this.dialog.formData = false;
					this.loadData();
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
				});
			} else if(this.dataForm.mode == 'edit') {
				this.dataForm.put(this.api + '/' + this.dataForm.id)
				.then((response) => {
					this.$Progress.finish();
					this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
					this.dialog.formData = false;
					this.loadData();
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
				});
			}
		},
		deleteData() {
			this.$Progress.start();
			this.dialog.deleteData = false;
			const selectedId = this.dataTable.selectedData.map(value => value.id);
			this.$httpAuth.delete(this.api + '/delete', {
				params: {
					id: selectedId
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.loadData();
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
	},
	watch: {
		'dataTable.params.filters': function(){
			this.loadData();
		},
    },
	created(){
		this.$e.emit('updateTitle', this.title);
	},
	mounted() {
		this.loadData();
	},
}
</script>